import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import BaseSingleSelect = require("Everlaw/UI/BaseSingleSelect");
import { EverColor } from "design-system";
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");

class BaseComboBox<T extends Base.Object>
    extends BaseSingleSelect<T>
    implements BaseSelect.Filtering<T, T>
{
    protected extraNewRowClass: string;
    override onSelect(elem: T | string, isNew?: boolean, self?: BaseComboBox<T>): void {}
    override onChange(elem: T | string, wasAdded: boolean, allSelected: T): void {}

    constructor(params: BaseComboBox.Params<T>) {
        super(Object.assign({ newOption: true }, params));
    }

    override canAdd(name: string, clazz: string): boolean {
        // is there an exact match to the current name?
        return !this.visible.some((obj) => {
            return (
                obj.node !== Dom.node(this.newNodes[clazz])
                && obj.element
                && this.equalsNew(obj.element, name)
            );
        });
    }

    hasTextbox(): boolean {
        return true;
    }

    override toggleNew(newNode: Dom.Nodeable): void {
        if (!Dom.isHidden(newNode)) {
            if (this._selected) {
                this.toggle(this._selected, false, false);
            }
            this.setSelected(newNode, true);
            this._onSelect(this.tb.getValue(), true);
        }
    }

    override updateNewNode(val: string, clazz: string, newIdx: number): boolean {
        return super.updateNewNode(val, clazz, 0);
    }

    override getValue(): string {
        return this.getTextValue();
    }

    protected override setupNewNode(newNode: HTMLElement, clazz: string): void {
        if (this.colorItems) {
            Dom.style(newNode, "borderLeft", "8px solid " + EverColor.EVERBLUE_40);
        }
        newNode.setAttribute("index", "0");
        if (this.extraNewRowClass) {
            Dom.addClass(newNode, this.extraNewRowClass);
        }
        this.connect(newNode, Input.tap, () => this.toggleNew(this.newNodes[clazz]));

        const container =
            this.forClass(clazz, true).body
            || Dom.create("div", { class: "table-body" }, this.menu, "first"); // used by Autocomplete
        Dom.place(newNode, container);
    }

    protected override shouldCreateNewNode(): boolean {
        return true;
    }

    protected override shouldShowNoResultsDiv(): boolean {
        return false;
    }
}

module BaseComboBox {
    export interface Params<T extends Base.Object> extends BaseSingleSelect.Params<T> {
        // Combo boxes pass a string if the element is new.
        onSelect?: (
            elemOrName: T | string,
            isNew?: boolean,
            self?: BaseSelect<T, T>,
            nodeSelected?: Dom.Nodeable,
        ) => void;
        // Extra class that allows user typed values to get extra styles. Use "new-row" to append
        // bold [new] to the end of the text. "text-row" to append [text]
        extraNewRowClass?: string;
    }
}

export = BaseComboBox;
