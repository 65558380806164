/**
 * Date/time zone functionality that requires access to the project time zone or date format.
 *
 * See DateUtil.ts for general date utilities.
 */

import {
    DateDisplayFormat,
    fullMonths,
    getRegionDefaultDateDisplayFormat,
    MOMENT_JS_DATE_FORMAT,
    MOMENT_JS_TIME_FORMAT,
    months,
    TimezoneN,
    displayDateTimeAsZoneWithFormat,
    asMoment,
    TimeDisplayFormat,
    getTimezoneToLocalOffsetMillis,
} from "Everlaw/DateUtil";
import { CURRENT } from "Everlaw/Project";
import Project = require("Everlaw/Project");
import Util = require("Everlaw/Util");
import * as moment from "moment-timezone";

/**
 * Some new formats, currently used in chron times
 */

export function displayShortDateTime(d: Date | number): string {
    return inProjectTimezone(d).format("MMM DD YYYY hh:mm a");
}

export function displayShortDate(d: Date | number): string {
    return inProjectTimezone(d).format("MMM DD YYYY");
}

export function displayShortDateWithComma(d: Date | number): string {
    return inProjectTimezone(d).format("MMM D, YYYY");
}

export function displayShortMonthYear(d: Date | number): string {
    return inProjectTimezone(d).format("MMM YYYY");
}

export function getProjectDateDisplayFormat(): DateDisplayFormat {
    return CURRENT ? CURRENT.dateDisplayFormat : getRegionDefaultDateDisplayFormat();
}

export function getProjectMomentJSDateFormat(): string {
    return MOMENT_JS_DATE_FORMAT[getProjectDateDisplayFormat()];
}

export function getProjectTimeDisplayFormat(): TimeDisplayFormat {
    return CURRENT ? CURRENT.timeDisplayFormat : TimeDisplayFormat.TWELVE_HOUR;
}

export function getProjectMomentJSTimeFormat(): string {
    return MOMENT_JS_TIME_FORMAT[getProjectTimeDisplayFormat()];
}

export function getShowTimezone(): boolean {
    return CURRENT ? CURRENT.appendTimezone : true;
}

/**
 * We don't display seconds in Searches as it can be misleading since we do not index by seconds.
 * If we display seconds a search for times between 3:00 am - 3:02 am will display as 3:00:00 am -
 * 3:02:00 am. This is because we use the lower values for the times when displaying, but when
 * searching we compute an upperBound calculated using precision, see DateTime.Value#getUpper. That
 * example search is actually 3:00:00 am - 3:02:59.999 am on the backend As we currently do not
 * replicate the logic to compute the true upperBound on the frontend, it is easier to simply not
 * display seconds in searches.
 */
export function getSearchTimeDisplayFormat(): string {
    const timeDisplayFormatName = getProjectTimeDisplayFormat();
    switch (timeDisplayFormatName) {
        case TimeDisplayFormat.TWELVE_HOUR_WITH_SECONDS:
            return MOMENT_JS_TIME_FORMAT[TimeDisplayFormat.TWELVE_HOUR];
        case TimeDisplayFormat.TWENTY_FOUR_HOUR_WITH_SECONDS:
            return MOMENT_JS_TIME_FORMAT[TimeDisplayFormat.TWENTY_FOUR_HOUR];
        case TimeDisplayFormat.TWELVE_HOUR:
        case TimeDisplayFormat.TWENTY_FOUR_HOUR:
            return MOMENT_JS_TIME_FORMAT[timeDisplayFormatName];
        default:
            throw new Error("Unmapped TimeFormat for removing seconds");
    }
}

// Parses the given date string into a UTC object using fmt if provided, then the current Project's
// date display format, and finally falling back to yyyy-mm-dd:
//  {
//      millis: milliseconds since epoch,
//      yr:     UTC year,
//      mo:     UTC month (1-indexed),
//      mon:    3-character UTC month string,
//      month:  full UTC month string
//      day:    UTC day
//  }
export function parseUTC(date: string, separator = "-", fmt?: DateDisplayFormat) {
    const splitDate = date
        .split(separator)
        .map(Util.toInt)
        .filter((x) => x != null);
    // We don't use getDateDisplayFormat() here because we want to always fall back to YMD instead
    // of the regional norm since this method used to only take in YMD-formatted strings.
    const currDateFormat = fmt ? fmt : CURRENT ? CURRENT.dateDisplayFormat : "YMD";

    const dayInd = currDateFormat.indexOf("D");
    const monthInd = currDateFormat.indexOf("M");
    const yearInd = currDateFormat.indexOf("Y");

    const jmo = splitDate[monthInd] - 1;
    return {
        millis: Date.UTC(splitDate[yearInd], jmo, splitDate[dayInd]),
        yr: splitDate[yearInd],
        mo: splitDate[monthInd],
        mon: months[jmo],
        month: fullMonths[jmo],
        day: splitDate[dayInd],
    };
}

export function displayYear(d: Date | number): string {
    return inProjectTimezone(d).format("YYYY");
}

/**
 * Displays the datetime in the time zone of the current project with the time zone appended.
 * @param d either a JS Date object or a timestamp in milliseconds since unix epoch
 */
export function displayDateTimeProjectFormatWithTimezone(d: Date | number): string {
    return displayDateTimeProjectFormat(d) + " (" + getProjectTimezone() + ")";
}

/**
 * Displays the datetime in the time zone of the current project with no time zone information.
 * @param d either a JS Date object or a timestamp in milliseconds since unix epoch
 */
export function displayDateTimeProjectFormat(d: Date | number): string {
    return inProjectTimezone(d).format(
        getProjectMomentJSDateFormat() + " " + getProjectMomentJSTimeFormat(),
    );
}

/**
 * Displays the date and time in the time zone of the user as determined by Moment with a comma
 * after the date.
 * @param d a timestamp in milliseconds since unix epoch
 */
export function displayDateTimeProjectFormatWithComma(d: number): string {
    return asMoment(d).format(
        getProjectMomentJSDateFormat() + ", " + getProjectMomentJSTimeFormat(),
    );
}

export function displayDateTimeThreadingFormat(d: Date | number): string {
    return inProjectTimezone(d).format("MMMM D, YYYY [at] h:mm:ss a");
}

/**
 * When we have a date with no time zone information, we calculate the time as though it were UTC.
 * @param value a timestamp in milliseconds since unix epoch
 */
export function displayDateTimeAsUtc(value: number) {
    return displayDateTimeAsZoneWithFormat(
        value,
        "UTC" as TimezoneN,
        getProjectMomentJSDateFormat() + " " + getProjectMomentJSTimeFormat(),
    );
}

/**
 * When we have a date with no time zone information, we calculate the time as though it were UTC
 * and then display it with (Unknown time zone) appended.
 * @param value a timestamp in milliseconds since unix epoch
 */
export function displayDateTimeAsUtcUnknownTimezone(value: number) {
    return displayDateTimeAsUtc(value) + " (Unknown time zone)";
}

export function displayDateTime(d: Date | number): string {
    return inProjectTimezone(d).format("YYYY/MM/DD hh:mm a");
}

export function displayDate(d: Date | number): string {
    return inProjectTimezone(d).format("YYYY/MM/DD");
}

export function displayTimeProjectFormat(d: Date): string {
    return inProjectTimezone(d).format(getProjectMomentJSTimeFormat());
}

export function displayFullDateProjectFormat(d: Date | number): string {
    return inProjectTimezone(d).format(getProjectMomentJSDateFormat());
}

export function displayMonthYear(d: Date | number): string {
    return inProjectTimezone(d).format("YYYY/MM");
}

/**
 * e.g. 1:01 am
 */
export function displayTime(d: Date | number): string {
    return inProjectTimezone(d).format("hh:mm a");
}

export function inProjectTimezone(d: Date | number): moment.Moment {
    return moment.tz(d, Project.CURRENT ? Project.CURRENT.timezoneId : "UTC");
}

export function getProjectTimezone(): string {
    return Project.CURRENT?.timezoneId || "UTC";
}

/**
 * Returns the offset in milliseconds between the project time zone and the local time zone.
 * The returned value should be added as milliseconds to the passed in timestamp in order
 * for the date to be cast in local time as the project time.
 *
 * This is useful when passing a date or timestamp to a component that then displays the date
 * in local time zone. If you want the display to match other displays in project time zone, you
 * need to account for that offset first.
 *
 * Example:
 *
 * Your local time zone is Pacific time, and your project time zone is UTC. You have a timestamp
 * corresponding to
 * 8/14/2024 5:00:00 PM Pacific Time - which is 8/15/2024 12:00:00 AM UTC.
 *
 * You want to display this time in the project timezone, but the component you are using displays
 * this timestamp as local time zone. By using this function, the offset returned when added to
 * the timestamp will result in
 * 8/15/2024 12:00:00 AM Pacific Time - which is what will be displayed (matching other project times).
 */
export function getProjectToLocalTimezoneOffset(d: Date | number): number {
    const timestamp = d instanceof Date ? d.getTime() : d;
    return -getTimezoneToLocalOffsetMillis(timestamp, getProjectTimezone() as TimezoneN);
}
