import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import BaseSelector = require("Everlaw/UI/BaseSelector");
import { clsx } from "clsx";
import Cmp = require("Everlaw/Core/Cmp");
import ColorUtil = require("Everlaw/ColorUtil");
import { EverColor } from "design-system";
import { everHashProp } from "Everlaw/EverAttribute/EverHash";
import { EVERCLASS, everClassProp } from "Everlaw/EverAttribute/EverClass";
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import LabeledIcon = require("Everlaw/UI/LabeledIcon");
import Popup = require("Everlaw/UI/Popup");
import Str = require("Everlaw/Core/Str");
import TextBox = require("Everlaw/UI/TextBox");
import Tooltip = require("Everlaw/UI/Tooltip");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");
import Widget = require("Everlaw/UI/Widget");

import dijit_focus = require("dijit/focus");

import dojo_keys = require("dojo/keys");
import dojo_on = require("dojo/on");
import dojo_window = require("dojo/window");

import { getFocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";

const DEFAULT_MAX_RENDERED_ELEMENTS = Infinity;
const MOUSEOVER_EVENT = new Event("mouseover");
const MOUSEOUT_EVENT = new Event("mouseout");

class SelectElement<T> {
    hidden = false;
    private lazyRow: BaseSelect.Row | null = null;

    constructor(
        public element: T,
        private createRow: () => BaseSelect.Row,
    ) {}

    hasRow(): boolean {
        return !!this.lazyRow;
    }

    row(): BaseSelect.Row {
        if (this.lazyRow === null) {
            this.lazyRow = this.createRow();
        }
        return this.lazyRow;
    }

    destroy() {
        if (this.lazyRow !== null) {
            Util.destroy(this.lazyRow.onDestroy);
            Dom.destroy(Dom.node(this.lazyRow));
        }
        this.lazyRow = null;
    }
}

interface SelectForClassParams<T> {
    elements: SelectElement<T>[];
    header: HTMLElement | null;
    body: HTMLElement | null;
    menu: HTMLElement | null;
    toDestroy: Util.Destroyable[];
    showHeader: boolean; // only used when the global headers variable is true
}

class SelectForClass<T> {
    elements: SelectElement<T>[];
    header: HTMLElement;
    body: HTMLElement;
    menu: HTMLElement;
    toDestroy: Util.Destroyable[];
    showHeader: boolean;

    constructor(params: SelectForClassParams<T>) {
        Object.assign(this, params);
    }

    displayElementByIndex(idx: number) {
        Dom.place(this.elements[idx].row().node, this.body);
    }
}

interface NodeWithNullableElement<T> {
    element?: T;
    node: HTMLElement;
}

interface SortedElement<T> {
    element: T;
    node: HTMLElement;
    beginOrder: number;
}

interface SortedClass<T> {
    elements: SortedElement<T>[];
    header: HTMLElement;
}

abstract class BaseSelect<S, T extends Base.Object>
    extends BaseSelector<S>
    implements UI.WidgetWithTextBox
{
    // CSS class for selected entries
    SELECTED_CLASS = "selected";
    // For subclasses that don't want the check icon used to represent selected items.
    dontShowSelectIcon = false;
    caseSensitive = false;
    classOrder: (Base.Class<T> | string)[] = [];
    classOrderOnSearch?: boolean = false;
    classOrderStr: string[] = [];
    clearMark = false;
    clearOnEmptyText = false;
    clickableIcon = false;
    colorItems = "element";
    customSearch?: (display: string, search: string) => boolean;
    handleCaptions: false;
    retainDefaultSearch = false;
    elements: T[][];
    maxRenderedElements: number;
    focusOnTap = false;
    getHeader?: (elem: T) => string;
    headers = true;
    matchWidth = true;
    menuMaxHeight: string;
    minimizePopupOnAll = false;
    nameMap: { [className: string]: string } = {};
    neverSelectAfter = false;
    // An array of classes of objects that can be added. Created by `Arr.wrap`ing the given
    // class(es). If no classes are provided, a default is added.
    newClassStrs: string[] = [];
    // An array of color hex strings that correspond to newClassStrs. Normally, the indices in
    // `newClassStrs` and `newColors` should correspond. Classes without corresponding indices in
    // `newColors` (ie classes for which idx >= newColors.length) will use the default color,
    // Everblue. This happens when no newClass is provided and a default class is used.
    newColors: string[] = [];
    // can you create new objects from this selector?
    newOption = false;
    placeholder: string | null = null;
    pluralize = true;
    pluralOverride: string;
    popup: boolean | string;
    // Additional css class to add to the popup
    popupClass: string;
    forceDirection?: boolean; // Should the popup always be in the direction specified in "popup"?
    selectOnSame = false;
    selectorStyle: Dom.StyleProps;
    selectorClass: string;
    style: Dom.StyleProps;
    tabIndex: number | undefined = undefined;
    tb: TextBox;
    textBoxParams: TextBox.Params | null = null;
    textBoxWidth = "100%";
    toggler: HTMLElement;
    togglerText: string;
    zIndex = "1000";
    manualFilter = false;
    stayFilteredOnSelect = false;
    // if isAllowMultiSelect is true together with stayFilteredOnSelect, then multiple result rows
    // can have the class BaseSelect::SELECTED_CLASS
    isAllowMultiSelect = false;
    truncateOnLeftWidth: number;
    usePress: boolean;
    hideNewSuffix = false;
    getClassForRow: (e: Base.Object) => string;
    ellipsifyText: boolean;
    // class added to div with content to style it as ellipsified
    ELLIPSIFY_CLASS = "ellipsify";
    mirrorTooltips: boolean;
    mirrorTooltipPosition: string[];
    protected leftTruncateOffset: number;
    protected static defaultPlaceholder = "Enter portion of name...";
    protected static defaultNewClass = "defaultNewClass";
    protected static defaultNewPlaceholder = "Enter existing/new name...";
    protected header: HTMLElement;
    protected newNodes: { [clazz: string]: BaseSelect.Row } = {};
    protected potentialValue: string = "";
    protected hovered: NodeWithNullableElement<T> | null = null;
    protected selectedIdx: number | null = null;
    protected visible: NodeWithNullableElement<T>[] = [];
    protected disabled = false;
    protected _elements: { [className: string]: SelectForClass<T> } = {};
    protected menu: HTMLElement;
    classesNode: HTMLElement;
    protected popupPositioner: Popup | null = null;
    protected noResults: HTMLElement;
    protected newClassesInlineClasses: Set<string> = new Set();
    protected totalOmittedElements: number = 0;
    protected showSelectedElementsFirst = false;
    protected isSelectedForSorting: (elem: T) => boolean;
    private menuFooter: HTMLElement;
    private rowBodyTooltips: Tooltip[] = [];
    // Elements with these ids should always be displayed if present, even if they would otherwise
    // be filtered out by the applied filter.
    protected alwaysDisplayedElementIds: (number | string)[];
    createNew(name: string, clazz: string, callback: (elem: any) => void) {}
    icon(elem: T): string | null {
        return null;
    }
    iconConfig(elem: T, icon: Icon) {}
    iconTooltip(elem: T): Dom.Content {
        return null;
    }
    iconFloatRight() {
        return true;
    }
    onChange(
        elem: T | string,
        wasAdded: boolean,
        allSelected: T | { [className: string]: T[] } | null,
    ) {}
    onFilter(val: string) {}
    override onFocus() {}
    onMinimize(me: BaseSelect<S, T>) {}
    onPopup() {}
    onSelect(
        elem: T | string,
        isNew?: boolean,
        selector?: BaseSelect<S, T>,
        selectedNode?: Dom.Nodeable,
    ) {}
    onTextBoxBlur() {}
    onUnselect(elem: T, unselectedNode?: Dom.Nodeable) {}
    onEnterKey(elem?: T, dontSelect?: boolean) {}
    readOnly(elem: T): string | null {
        return null;
    }
    protected setValueOnBlur() {}
    protected toggleNew(newNode: Dom.Nodeable) {}
    abstract hasTextbox(): boolean;
    abstract unselect(elem_s?: T, silent?: boolean): void;
    protected abstract doKeySelect(): void;
    protected abstract _onToggleInner(elem: T, add: boolean, silent: boolean): void;
    protected abstract _onSelect(elem: T, isNew?: boolean, selectedNode?: Dom.Nodeable): void;
    protected abstract _onUnselect(elem: T, isNew?: boolean, unselectedNode?: Dom.Nodeable): void;
    /**
     * If set, will prevent the TextBox's onFocus from triggering.
     */
    private suppressTextBox = false;
    protected showReadOnlyIcon = false;
    protected readOnlyIconClass = "lock-20";
    protected showDisabledReadOnly = false;
    private showReadOnlySelected = false;
    private makeElementsFocusable: boolean;
    private elementFocusStyling: string | string[];
    protected afterFilter: () => void;
    constructor(params: BaseSelect.Params<S, T>) {
        super(params.selectDiv || Dom.div({ class: "select" }));
        Object.assign(this, params);
        this.maxRenderedElements = this.maxRenderedElements || DEFAULT_MAX_RENDERED_ELEMENTS;
        this.newClassStrs = params.newClass
            ? Arr.wrap(params.newClass).map((newClass) => newClass.prototype.className)
            : [];
        this.newColors = params.newColor ? Arr.wrap(params.newColor) : [];
        this.menu = Dom.div({
            class: "select-menu nofocus",
            tabindex: 0,
            ...everClassProp(EVERCLASS.BASE_SELECT.BASE_SELECT),
        });

        if (this.menuMaxHeight) {
            Dom.style(this.menu, "maxHeight", this.menuMaxHeight);
        }
        if (this.style) {
            Dom.style(this.menu, this.style);
        }
        if (this.selectorStyle) {
            Dom.style(this.node, this.selectorStyle);
        }
        if (this.selectorClass) {
            Dom.addClass(this.node, this.selectorClass);
        }
        this._elements = {};
        this.classOrderStr = [];
        (this.classOrder || []).forEach((clazz) => {
            if (Is.string(clazz)) {
                this.addClass(clazz);
            } else {
                this.addClass(this.getClassName(clazz.prototype));
            }
        });
        this.classesNode = Dom.place(Dom.div(), this.menu);
        this.menuFooter = Dom.place(Dom.div({ class: "select-menu-footer" }), this.menu);
        if (params.newClassesInline) {
            this.createNewClassesInline(params.newClassesInline);
            Object.keys(params.newClassesInline).forEach((className) =>
                this.newClassesInlineClasses.add(className),
            );
        }
        // passed in an empty list or a list of EBOs directly
        const elems = params.elements || [];
        if (elems.length === 0 || !Is.array(elems[0])) {
            this.elements = [<T[]>elems];
        }
        this.computeLeftTruncateOffset(this.elements);
        this.elements = this.elements.map((list) => {
            list = Arr.sorted(list, { cmp: this.comparator });
            list.forEach(this.addElement, this);
            return list;
        });
        if (this.placeholder == null) {
            this.placeholder = this.newOption
                ? BaseSelect.defaultNewPlaceholder
                : BaseSelect.defaultPlaceholder;
        }
        Dom.addClass(this.menu, "filtering");
        this.popupClass && Dom.addClass(this.menu, this.popupClass);
        this.createNewNodesIfApplicable();

        const textBoxParams = Object.assign(
            {
                focusOnTap: this.focusOnTap,
                placeholder: this.placeholder,
                width: this.textBoxWidth,
                preventBrowserAutocomplete: true,
                tabIndex: this.tabIndex,
                textBoxAriaLabel: params.textBoxAriaLabel,
                textBoxLabelContent: params.textBoxLabelContent,
                textBoxLabelPosition: params.textBoxLabelPosition,
                textBoxContainer: params.textBoxContainer,
                clearMark: params.clearMark,
                clearMarkOnClick: () => {
                    this.clear();
                    this.selectInitial(false);
                },
            },
            this.textBoxParams || {},
        );

        const onTextBoxChange = textBoxParams.onChange;
        const onTextBoxKeyDown = textBoxParams.onKeyDown;
        const onTextBoxFocus = textBoxParams.onFocus;
        const onTextBoxBlur = textBoxParams.onBlur;
        const onTextBoxSubmit = textBoxParams.onSubmit;

        Object.assign(textBoxParams, {
            onChange: (val: string) => {
                onTextBoxChange?.(val);
                this.filter(val)?.then(() => this.afterFilter?.());
            },
            onKeyDown: (k: number, evt: KeyboardEvent) =>
                this._handleKey(k, evt) && (!onTextBoxKeyDown || onTextBoxKeyDown(k, evt)),
            onFocus: () => {
                this.onTextBoxFocus();
                onTextBoxFocus?.();
            },
            onBlur: () => {
                this._onTextBoxBlur();
                onTextBoxBlur?.();
            },
            onSubmit: (val: string, withShiftKey?: boolean) => {
                this.enterKey();
                onTextBoxSubmit?.(val, withShiftKey);
            },
        });
        this.buildTextBox(textBoxParams);
        this.registerDestroyable(this.tb);

        this.wrapIcon();
        // We no longer need the menu itself to be tab focusable.
        Dom.removeAttr(this.menu, "tabindex");
        if (this.popup) {
            this.popupPositioner = new Popup({
                content: this.menu,
                direction: <string>this.popup,
                reference: this.hasTextbox() ? this.tb.getInput() : this.node,
                forceDirection: this.forceDirection,
                zIndex: this.zIndex,
                maxHeight:
                    this.menuMaxHeight
                    && this.menuMaxHeight.length > 2
                    && this.menuMaxHeight.indexOf("px") === this.menuMaxHeight.length - 2
                        ? parseInt(this.menuMaxHeight)
                        : undefined,
                matchWidth: this.matchWidth,
            });
            if (!this.hasTextbox()) {
                if (!this.toggler) {
                    this.toggler = Dom.div({ class: "select-toggler" }, this.togglerText);
                }
                Dom.place(this.toggler, this);
                this.connect(this.toggler, Input.tap, () => {
                    if (this.disabled) {
                        return;
                    }
                    this.popupPositioner?.toggle();
                    this.onPopup();
                });
                if (params.makeFocusable) {
                    const focusDiv = makeFocusable(
                        this.toggler,
                        params.focusStyling || "focus-with-space-style",
                        "after",
                    );
                    this.registerDestroyable([
                        focusDiv,
                        Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => {
                            if (this.disabled) {
                                return;
                            }
                            this.popupPositioner?.toggle();
                            this.onPopup();
                            // There are a number of operations that get performed on the items
                            // in the BaseSelect before they're displayed, and their FocusDivs
                            // can get lost when they're moved around. We go through and replace
                            // the FocusDivs of the visible elements here.
                            if (this.visible.length) {
                                for (let i = 0; i < this.visible.length; i++) {
                                    getFocusDiv(this.visible[i].node)?.replace();
                                }
                                const lastFocusDiv = getFocusDiv(
                                    this.visible[this.visible.length - 1].node,
                                );
                                lastFocusDiv && lastFocusDiv.focus();
                            }
                        }),
                    ]);
                }
                if (this.minimizePopupOnAll) {
                    // Unfortunately, selecting an item from the popup causes an onBlur event that
                    // can occur before the onSelect event, so if we try to minimize the popup in an
                    // onBlur handler it can prevent the select event from even happening. The
                    // not-so-great solution is to listen to document.body and minimize when the
                    // toggler (or its descendents) are not tapped.
                    this.connect(document.body, Input.tap, (evt) => {
                        if (
                            evt.target !== this.toggler
                            && !this.toggler.contains(<Node>evt.target)
                        ) {
                            this.minimize();
                            this.onPopup();
                        }
                    });
                }
            } else {
                Dom.place(this.tb, this.node);
                Dom.addClass(this.menu, "popup-select-menu");
                Dom.setAttr(this.menu, "dijitpopupparent", this.tb.getFocusContainerId());
            }
        } else {
            Dom.place(this.menu, this.node);
            Dom.place(this.tb.getNode(), this.menu, "before");
            Dom.style(this.node, "position", "relative");
        }
        this.header = Dom.create(
            "div",
            {
                class: "hidden table-row",
                style: {
                    backgroundColor: "white",
                    fontStyle: "italic",
                    fontSize: "12px",
                },
            },
            this.menu,
            "first",
        );
        this.createNoResultsRow();
        this.buildVisible("", this.customSearch, this.retainDefaultSearch);
        this.updateIndexes();
        this.selectInitial();
    }

    protected buildTextBox(textBoxParams: TextBox.Params): void {
        this.tb = new TextBox(textBoxParams);
    }
    getNoResultsNode() {
        return this.noResults;
    }
    scrollToBottom() {
        this.menu.scrollTop = this.menu.scrollHeight - this.menu.clientHeight;
    }
    scrollToTop() {
        this.menu.scrollTop = 0;
    }
    /**
     * Clicking the textbox's icon should not open the dropdown. This adds some event listeners to
     * the icon to prevent the textbox from getting focused, and thus the dropdown from opening.
     */
    private wrapIcon(icon = this.tb.icon) {
        if (!icon || this.clickableIcon) {
            return;
        }
        const oldOnClick = icon.onClick;
        icon.onClick = (event: Event) => {
            Widget.blurAll();
            // Don't suppress the text box focus action if the click was triggered by keyboard,
            // since it won't be unsuppressed unless the mouse leaves the icon.
            if ((<PointerEvent>event).detail > 0) {
                this.suppressTextBox = true;
            }
            oldOnClick && oldOnClick(event);
        };

        // If the cursor is over the icon, we don't want a focus or click event to open the dropdown
        // menu.
        dojo_on(icon.node, Input.enter, () => {
            this.suppressTextBox = true;
        });
        dojo_on(icon.node, Input.leave, () => {
            this.suppressTextBox = false;
        });
    }
    add(elem: T, silent = false) {
        let added = false;
        this.searchElement(
            elem,
            undefined,
            (clazz, idx) => {
                this.addElement(elem, idx);
                added = true;
            },
            true,
        );
        if (added && !silent) {
            this.onUpdate();
        }
        return added;
    }
    addMultiple(elems: T[], silent = false) {
        let found = false;
        elems.forEach((elem) => (found = this.add(elem, true) || found));
        if (found && !silent) {
            this.onUpdate();
        }
    }
    addNew(clazz?: string) {
        // If no class provided, use the first in the list of new classes.
        // newClassStrs is always length >= 1 because the default class will
        // be added if no classes were provided.
        clazz = clazz || this.defaultNewClassStr();
        // make a new value with the full right id and name
        this.createNew(this.potentialValue, clazz, (newElem) => {
            this.searchElement(newElem, undefined, (clz, idx) => {
                const added = this.addElement(newElem, idx);
                if (!this.hideNewSuffix) {
                    Dom.addClass(added.row(), "added-row");
                }
                // Refresh the display so that the new element is correctly incorporated into this._visible
                // and so is highlightable/selectable.
                this.onUpdate();
            });
            Object.values(this.newNodes).forEach((row) => Dom.hide(row));
            this.potentialValue = "";
            this.select(newElem);
        });
    }
    override blur() {
        super.blur();
        if (this.menu.contains(dijit_focus.curNode)) {
            Widget.blurAll();
        }
        this.closeAllRowBodyTooltips();
    }
    canAdd(name: string, clazz: string) {
        return !Base.get(clazz).some((obj) => {
            return this.equalsNew(<T>obj, name);
        });
    }
    clear() {
        this.setValue("");
        this._filter("");
        this.closeAllRowBodyTooltips();
    }
    closeAllRowBodyTooltips(): void {
        this.rowBodyTooltips.forEach((tooltip) => tooltip.close());
    }
    comparator(x: T, y: T) {
        return x.compare(y);
    }
    override destroy() {
        super.destroy();
        if (this.popupPositioner) {
            this.popupPositioner.destroy();
        }
        if (this._elements) {
            Object.values(this._elements).forEach((byClass) => {
                byClass.elements.forEach((e) => e.destroy());
                Util.destroy(byClass.toDestroy);
            });
        }
        Dom.destroy(this.menu);
    }
    enterKey(dontSelectAfter = false) {
        if (this.hovered) {
            if (this.isNewNode(this.hovered.node)) {
                if (this.newOption) {
                    let clazz: string | undefined = undefined;
                    Object.entries(this.newNodes).forEach(([newClazz, row]) => {
                        if (Dom.node(row) === (this.hovered && Dom.node(this.hovered))) {
                            clazz = newClazz;
                            return false;
                        }
                    });
                    this.addNew(clazz);
                }
                this.toggleNew(this.hovered);
            } else if (this.hovered.element) {
                this.toggle(this.hovered.element);
            }
        }
        if (!dontSelectAfter && !this.neverSelectAfter) {
            this.tb.select();
        }
        this.onEnterKey(this.hovered?.element, dontSelectAfter);
    }
    equalsNew(existing: T, name: string) {
        return (this.caseSensitive ? Cmp.str : Cmp.strCI)(existing.display(), name) === 0;
    }
    filter(val: string) {
        const promise = this._filter(val);
        if ((!val || val === "") && this.clearOnEmptyText) {
            this.unselect();
        }
        this.onFilter(val);
        return promise;
    }
    override focus() {
        this.tb.focus();
        this.onFocus();
    }
    // Updates the display of elements by reapplying the current filter
    // TODO: this.toggleUserHidden should be handling this.
    //      Currently being used by batch redaction panel to rerender headers
    forcedUpdate() {
        this.onUpdate();
    }
    getClassName(elem: T) {
        if (this.getHeader) {
            return this.getHeader(elem);
        }
        return elem.className;
    }
    getMenu() {
        return this.menu;
    }
    getMenuFooter() {
        return this.menuFooter;
    }
    getTextValue() {
        return this.tb.getValue();
    }
    handleKey(k: number, evt: KeyboardEvent) {
        return true;
    }
    // hide an element that is in the selector
    hide(elems: T | T[]) {
        this.toggleUserHidden(elems, true);
    }
    isNewNode(n: HTMLElement) {
        return Object.values(this.newNodes).some((newNode) => n === Dom.node(newNode));
    }
    isNewHovered() {
        return this.hovered && this.isNewNode(this.hovered.node);
    }
    minimize() {
        this.popupPositioner && this.popupPositioner.hide();
        this.onMinimize(this);
        this.closeAllRowBodyTooltips();
    }
    hasPopup() {
        return this.popupPositioner && !this.popupPositioner.isHidden();
    }
    openPopup() {
        this.popupPositioner && this.popupPositioner.show();
    }
    onMouseOut(elem: T | null, node: Dom.Nodeable) {
        this.setUnhovered(elem, node);
    }
    // when you mouse over, the selection should jump to that row
    onMouseOver(elem: T | null, node: Dom.Nodeable) {
        this.setHovered(elem, node);
    }

    setUnhovered(elem: T | null, node: Dom.Nodeable) {
        if (this.node.contains(document.activeElement)) {
            return;
        }
        const idx = Dom.getAttr(node, "index");
        // Make sure there is actually a number here
        if (Is.number(idx) && this.hovered && this.hovered.node === node) {
            this.unhover();
        }
    }

    setHovered(elem: T | null, node: Dom.Nodeable) {
        const idx = Dom.getAttr(node, "index");
        // Make sure there is actually a number here
        if (idx != null && Is.number(idx)) {
            this.selectedIdx = Util.toInt(idx);
            this.drawSelectedByCursor(false);
        }
    }

    remove(elem: T, silent = false) {
        let found = false;
        this.searchElement(
            elem,
            (clazz, idx) => {
                this.removeElement(elem, idx, silent);
                found = true;
            },
            undefined,
            true,
        );
        if (found && !silent) {
            this.onUpdate();
        }
        return found;
    }
    removeMultiple(elems: T[], silent = false) {
        let found = false;
        elems.forEach((elem) => (found = this.remove(elem, true) || found));
        if (found && !silent) {
            this.onUpdate();
        }
    }
    removeAll(silent = false) {
        if (this._elements) {
            Object.values(this._elements).forEach((clazz) => {
                for (let idx = clazz.elements.length - 1; idx >= 0; idx--) {
                    const e = clazz.elements[idx];
                    this.removeElement(e.element, idx, true);
                }
            });
        }
        !silent && this.onUpdate();
    }
    reset() {
        if (this._elements) {
            Object.values(this._elements).forEach((clazz) => {
                clazz.elements.forEach((e: SelectElement<T>) => {
                    if (e.hasRow()) {
                        this.setSelected(e.row(), false);
                    }
                    e.hidden = false;
                });
            });
        }
        this.onUpdate();
    }
    setElemDisabled(elem: T, disabled: boolean, opacity = "0.5") {
        this.searchElement(
            elem,
            (clazz, idx) => {
                Dom.style(clazz.elements[idx].row(), {
                    pointerEvents: disabled ? "none" : "",
                    opacity: disabled ? opacity : "",
                });
            },
            undefined,
            false,
        );
    }
    isDisabled() {
        return this.disabled;
    }
    setDisabled(disabled: boolean) {
        this.tb.setDisabled(disabled);
        this.disabled = disabled;
    }
    setHeader(content: Dom.Content) {
        Dom.setContent(this.header, content);
    }
    styleHeader(styles: Dom.StyleProps) {
        Dom.style(this.header, styles);
    }
    override setWidth(width: string) {
        if (this.hasTextbox()) {
            this.setTextboxWidth(width);
        }
    }
    setTextboxWidth(width: string) {
        this.tb.setWidth(width);
    }
    override setValue(val: S | string, silent?: boolean) {
        let valStr: string;
        if (Is.string(val)) {
            valStr = <string>val;
        } else {
            this.select(<S>val, silent);
            valStr = val ? this.display(val) : "";
        }
        this.tb.setValue(valStr);
    }
    // unhide an element that is in the selector
    show(e: T | T[]) {
        this.toggleUserHidden(e, false);
    }
    showAll(viz: (elem: T) => boolean) {
        this.forEachElement((e) => {
            e.hidden = !viz(e.element);
        });
        this.onUpdate();
    }
    toggleHeader(show: boolean) {
        Dom.show(this.header, show);
    }
    updateMenuMaxHeight(max: string) {
        if (max !== this.menuMaxHeight) {
            this.menuMaxHeight = max;
            Dom.style(this.menu, "maxHeight", this.menuMaxHeight);
        }
    }
    updateMenuMinWidth(min: string) {
        Dom.style(this.menu, "minWidth", min);
    }
    updateNewNode(val: string, clazz: string, newIdx: number) {
        if (this.newOption) {
            return this._updateNewNode(val, clazz, newIdx);
        }
        return false;
    }
    private getHeaderContent(className: string) {
        const header = Str.pluralForm(
            Str.camelToHuman(className || "Unknown"),
            this.pluralize ? 2 : 1,
            this.pluralOverride,
        );
        return Util.getDefault(this.nameMap, className, header);
    }

    // create the table holding elements of this class
    protected addClass(className: string) {
        const menu = Dom.create("div", { class: "select-menu-class-div" }, this.classesNode);
        const displayName = this.getHeaderContent(className);
        const header = Dom.create(
            "div",
            {
                class: "table-header hidden",
                content: displayName,
            },
            menu,
        );
        const body = Dom.create("div", { class: "table-body" }, menu);
        this._elements[className] = new SelectForClass<T>({
            elements: [],
            header,
            body,
            menu,
            toDestroy: [],
            showHeader: true,
        });
        // just push this class onto the end of the list of classes
        this.classOrderStr.push(className);
        return this._elements[className];
    }
    protected addElement(e: T, idx: number): SelectElement<T> {
        const createRow = () => {
            const row = this.prepRowElement(e);

            if (this.colorItems) {
                let color: string;
                if (Is.func(this.colorItems)) {
                    color = this.colorItems(e);
                } else {
                    color =
                        this.colorItems === "element"
                            ? ColorUtil.colorAsHex(e, EverColor.EVERBLUE_40)
                            : this.colorItems === "default"
                              ? EverColor.EVERBLUE_40
                              : this.colorItems;
                }
                Dom.style(row, "borderLeft", "8px solid " + color);
            }
            Dom.toggleClass(row, "colored", !!this.colorItems);

            const readOnly = this.readOnly(e);
            const tooltip = this.iconTooltip(e);
            const iconClass = this.icon(e) || ""; // Empty string case won't actually be used.
            const displayReadOnlyElems = readOnly && this.showReadOnlyIcon;
            if (displayReadOnlyElems || iconClass) {
                const icon = new Icon(displayReadOnlyElems ? this.readOnlyIconClass : iconClass, {
                    tooltip: displayReadOnlyElems ? readOnly : tooltip,
                });
                this.iconConfig(e, icon);
                Dom.place(icon.node, row, "last");
                if (this.iconFloatRight()) {
                    Dom.style(row, {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    });
                    Dom.style(icon.node, { margin: "-2px -2px -2px 8px" });
                }
            }
            if (this.showDisabledReadOnly && readOnly) {
                Dom.toggleClass(row, "disabled");
            }
            if (!readOnly) {
                row.onDestroy.push(
                    dojo_on(Dom.node(row), this.usePress ? Input.press : Input.tap, () => {
                        this.onClick();
                        this.toggle(e, false);
                    }),
                );
                if (this.makeElementsFocusable) {
                    const focusDiv = makeFocusable(
                        row.node,
                        this.elementFocusStyling || "focus-with-space-style",
                        "after",
                    );
                    row.onDestroy.push(
                        focusDiv,
                        Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => {
                            this.onClick();
                            this.toggle(e, false);
                        }),
                    );
                }
            }
            row.onDestroy.push(
                dojo_on(Dom.node(row), "mouseover", () => {
                    this.onMouseOver(e, Dom.node(row));
                }),
            );
            row.onDestroy.push(
                dojo_on(Dom.node(row), "mouseout", () => {
                    this.onMouseOut(e, Dom.node(row));
                }),
            );
            const rowClass = this.getClassForRow && this.getClassForRow(e);
            rowClass && Dom.addClass(row, rowClass);
            this.mirrorTooltips && this.createMirrorTooltip(row);
            return row;
        };
        return this._addElement(e, idx, createRow);
    }
    protected createMirrorTooltip(row: BaseSelect.Row): void {
        const mirrorTooltip = this.getMirrorTooltip(row);
        this.rowBodyTooltips.push(mirrorTooltip);
        row.onDestroy.push(mirrorTooltip);
    }
    protected getMirrorTooltip(row: BaseSelect.Row): Tooltip.MirrorTooltip {
        return new Tooltip.MirrorTooltip(row.node, undefined, this.mirrorTooltipPosition);
    }
    protected _addElement(e: T, idx: number, createRow: () => BaseSelect.Row): SelectElement<T> {
        const element = new SelectElement<T>(e, createRow);

        const byClass = this.forClass(this.getClassName(e), true);
        if (this.headers && byClass.showHeader) {
            Dom.show(byClass.header);
        }
        byClass.elements.splice(idx, 0, element);

        return element;
    }
    /**
     * Determines the beginOrder for each matching element. The begin order is a measure of whether
     * the search term is found at the beginning of a word in the element. The higher the beginOrder,
     * the farther the word is in the element. If the search term is found in the middle of the word
     * the beginOrder will be Number.MAX_VALUE.
     *
     * To allow for HTML formatting, we just keep the default beginOrder if the element isn't a
     * string.
     */
    protected findBeginOrder(
        disps: Dom.Content,
        uval: string,
        sortedElementsInClass: SortedElement<T>[],
        e: T,
        row: BaseSelect.Row,
    ) {
        let beginOrder = Number.MAX_VALUE;
        if (Is.string(disps)) {
            const index = disps.toLowerCase().indexOf(uval.toLowerCase());
            //the search value is at the beginning of the element string
            if (index === 0) {
                beginOrder = 0;
                //the search value is at the beginning of a word within the element string somewhere
            } else if (disps.charAt(index - 1) === " ") {
                beginOrder = disps.substring(0, index).split(" ").length;
            }
        }
        sortedElementsInClass.push({ element: e, node: Dom.node(row), beginOrder });
    }
    /**
     * Places each sorted category list into order based on the first value in each
     * category. This way the category with the top result will show up first but
     * the elements within a category won't be broken up.
     */
    protected addElementListToClass(
        classEmpty: boolean,
        sortedElementsInClass: SortedElement<T>[],
        classOrderList: SortedClass<T>[],
        entry: SelectForClass<T>,
    ) {
        let addedToClass = false;
        if (!classEmpty) {
            //adding the name of the category to the list if there are items in the category
            for (let i = 0; i < classOrderList.length; i++) {
                if (
                    classOrderList[i].elements[0].beginOrder > sortedElementsInClass[0].beginOrder
                ) {
                    if (!this.classOrderOnSearch) {
                        //reordering the dom to reorder the categories
                        const node = entry.header.parentElement;
                        const refNode = classOrderList[i].header.parentElement;
                        node && refNode && Dom.place(node, refNode, "before");
                    }
                    classOrderList.splice(i, 0, {
                        elements: sortedElementsInClass,
                        header: entry.header,
                    });
                    addedToClass = true;
                    break;
                }
            }
            if (!addedToClass) {
                classOrderList.push({ elements: sortedElementsInClass, header: entry.header });
            }
        }
    }
    /**
     * Resets the order of the categories in the dom so that they match those found in
     * this.classOrderStr. If buildVisible has been called before then it's possible that they're
     * out of order.
     */
    protected resetClasses() {
        const classOrder: HTMLElement[] = [];
        this.classOrderStr.forEach((baseClass) => {
            const header = this._elements[baseClass].header;
            // We ran into an interesting bug in IE. This method was being called an additional time
            // relative to other browsers, and in that additional call, header.parentElement was
            // null. It's unclear why this additional call was made or why parentElement was null,
            // but for the time being this annoying series of null checks should prevent errors
            // from happening.
            header && header.parentElement && classOrder.push(header.parentElement);
        });
        Dom.place(classOrder, this.classesNode);
    }
    /**
     * Reorders the categories and the elements within the categories based on whether the search
     * term is found at the beginning of a word in the element.
     *
     * The optional matchingFunc parameter allows the setting of a custom function for determining
     * which entries match the search term.
     */
    protected buildVisible(
        val: string,
        matchingFunc?: (disp: string, uval: string) => boolean,
        retainIsMatch?: boolean,
    ) {
        this.resetClasses();
        const uval = val.toLowerCase().trim();
        let isMatch: (disp: string) => boolean;
        if (matchingFunc && retainIsMatch) {
            isMatch = (disp) => {
                return (
                    !Is.string(disp)
                    || disp.toLowerCase().indexOf(uval) !== -1
                    || matchingFunc(disp, uval)
                );
            };
        } else if (matchingFunc) {
            isMatch = (disp) => matchingFunc(disp, uval);
        } else {
            isMatch = (disp) => {
                return !Is.string(disp) || disp.toLowerCase().indexOf(uval) !== -1;
            };
        }
        this.visible = [];
        //used to reorder the categories
        const classOrderList: SortedClass<T>[] = [];
        this.totalOmittedElements = 0;
        this.classOrderStr.forEach((baseClass: string) => {
            let classEmpty = true;
            let omittedElements = 0;
            const entry = this._elements[baseClass];
            const elements = entry.elements;
            //used to reorder the elements within a class
            const sortedElementsInClass: SortedElement<T>[] = [];

            elements.forEach((e) => {
                const element = e.element;
                if (!e.hidden) {
                    let caps: string[] = [];
                    const disps: string[] = this.getDisplayValues(element, this);
                    const filterValues = this.getFilterValues(element, this);
                    if (this.handleCaptions) {
                        caps = this.getCaptionValues(element, uval);
                    }
                    const elemIsAlwaysDisplayed =
                        this.alwaysDisplayedElementIds
                        && this.alwaysDisplayedElementIds.some((s) => s === element.id);
                    // If the element matches the input (or is part of a class that
                    // should always be displayed) put it into the list in order
                    if (
                        uval.length === 0
                        || filterValues.some(isMatch)
                        || (this.handleCaptions && caps.some(isMatch))
                        || elemIsAlwaysDisplayed
                    ) {
                        // If showSelectedElementsFirst is true, always include selected
                        // elements in visible.
                        if (
                            sortedElementsInClass.length < this.maxRenderedElements
                            || (this.showSelectedElementsFirst && this._isSelectedForSorting(e))
                        ) {
                            const row = e.row();
                            // No need to highlight matches for elements displayed regardless of match.
                            if (!elemIsAlwaysDisplayed) {
                                this.handleCaptions
                                    ? this.highlightMatchesWithCaptions(row, disps, caps, uval)
                                    : this.highlightMatches(row, disps, uval);
                            }
                            this.findBeginOrder(
                                disps[0],
                                uval,
                                sortedElementsInClass,
                                element,
                                row,
                            );
                            classEmpty = false;

                            const readOnly = this.readOnly(element);
                            const label =
                                this.showDisabledReadOnly && readOnly
                                    ? readOnly
                                    : this.getRowBodyTooltips(element, this);
                            if (label) {
                                const rowBodyTooltip = new Tooltip(row.node, label);
                                this.rowBodyTooltips.push(rowBodyTooltip);
                                row.onDestroy.push(rowBodyTooltip);
                            }
                        } else {
                            omittedElements++;
                        }
                    }
                }
            });
            this.totalOmittedElements += omittedElements;
            sortedElementsInClass.sort((a, b) => {
                return a.beginOrder - b.beginOrder;
            });
            //place the nodes in the newly sorted order
            if (entry.body) {
                const newNode = this.newNodes[baseClass];
                const sortedElementsForDisplay = [...sortedElementsInClass];
                if (this.showSelectedElementsFirst) {
                    sortedElementsForDisplay.sort((a, b) => {
                        if (this._isSelectedForSorting(a) && !this._isSelectedForSorting(b)) {
                            return -1;
                        } else if (
                            !this._isSelectedForSorting(a)
                            && this._isSelectedForSorting(b)
                        ) {
                            return 1;
                        } else {
                            return a.beginOrder - b.beginOrder;
                        }
                    });
                }
                const sortedNodes = sortedElementsForDisplay.map((e) => e.node);
                Dom.setContent(entry.body, newNode ? newNode.node : null, sortedNodes);
            }
            this.addElementListToClass(classEmpty, sortedElementsInClass, classOrderList, entry);
            if (entry.header) {
                const someOmitted = omittedElements > 0;
                const hideHeader =
                    (classEmpty && !this.newClassesInlineClasses.has(baseClass))
                    || !this.headers
                    || !entry.showHeader;
                const omittedMsg = `${omittedElements} omitted`;
                if (!hideHeader) {
                    const defaultHeader = this.getHeaderContent(baseClass);
                    if (someOmitted) {
                        Dom.setContent(entry.header, `${defaultHeader} (${omittedMsg})`);
                    } else {
                        Dom.setContent(entry.header, defaultHeader);
                    }
                } else if (someOmitted) {
                    Dom.setContent(entry.header, omittedMsg);
                }
                Dom.show(entry.header, !hideHeader || someOmitted);
            }
            if (Arr.contains(this.newClassStrs, baseClass)) {
                this.updateNewNode(val, baseClass, this.visible.length);
            }
        });
        //creating this.visible with the correct ordering of elements
        classOrderList.forEach((classList) => {
            if (this.showSelectedElementsFirst) {
                classList.elements
                    .filter((elementInList) => this._isSelectedForSorting(elementInList))
                    .forEach((elementInList) => {
                        this.visible.push({
                            element: elementInList.element,
                            node: elementInList.node,
                        });
                    });
                classList.elements
                    .filter((elementInList) => !this._isSelectedForSorting(elementInList))
                    .forEach((elementInList) => {
                        this.visible.push({
                            element: elementInList.element,
                            node: elementInList.node,
                        });
                    });
            } else {
                classList.elements.forEach((elementInList) => {
                    this.visible.push({ element: elementInList.element, node: elementInList.node });
                });
            }
        });
    }

    getVisible() {
        return this.visible;
    }
    private _isSelectedForSorting(e: SelectElement<T> | SortedElement<T>) {
        const node = e instanceof SelectElement ? e.row().node : e.node;
        return this.isSelectedForSorting
            ? this.isSelectedForSorting(e.element)
            : Dom.hasClass(node, this.SELECTED_CLASS);
    }
    protected getDisplayValues(e: T, me: BaseSelect<S, T>) {
        return [this.shortDisplay(e)];
    }
    protected getFilterValues(e: T, me: BaseSelect<S, T>) {
        return this.getDisplayValues(e, me);
    }
    protected getCaptionValues(e: T, val: string): [] {
        return [];
    }
    protected getRowBodyTooltips(e: T, me: BaseSelect<S, T>) {
        return "";
    }
    /**
     * Add the "+ Create new ..." row to applicable tables. This is done by creating a new
     * table element, similar to the element rows table, for the defined class sections. This new
     * table will consist of only the "+ Create new ..." row.
     */
    private createNewClassesInline(newClassesInline: BaseSelect.NewClassesInline<S, T>) {
        if (newClassesInline) {
            Object.entries(newClassesInline).forEach(([className, newClassInline]) => {
                const selectForClass = this.forClass(className, true);
                const classDisplay = newClassInline.classDisplay || className.toLowerCase();
                const addNewNode = Dom.node(this.prepNewClassInlineRowElement(classDisplay));
                newClassInline.color
                    && Dom.style(addNewNode, "borderLeft", `8px solid ${newClassInline.color}`);
                Dom.place(
                    addNewNode,
                    Dom.create("div", { class: "table-body" }, selectForClass.menu),
                );
                const inputType = this.usePress ? Input.press : Input.tap;
                selectForClass.toDestroy.push(
                    dojo_on(Dom.node(addNewNode), inputType, () => {
                        newClassInline.createNew(this);
                    }),
                );
                selectForClass.toDestroy.push(
                    dojo_on(addNewNode, "mouseover", () => {
                        Dom.addClass(addNewNode, "hovered");
                        this.unhover();
                    }),
                );
                selectForClass.toDestroy.push(
                    dojo_on(addNewNode, "mouseout", () => {
                        Dom.removeClass(addNewNode, "hovered");
                    }),
                );
            });
        }
    }
    protected createNewNodesIfApplicable() {
        if (this.shouldCreateNewNode()) {
            // If there's no given list of class strings, create a dummy default
            // class to put the new node under.
            if (this.newClassStrs.length === 0) {
                this.newClassStrs.push(BaseSelect.defaultNewClass);
                this.classOrderStr.unshift(BaseSelect.defaultNewClass);
                this._elements[BaseSelect.defaultNewClass] = new SelectForClass<T>({
                    header: null,
                    body: null,
                    menu: null,
                    toDestroy: [],
                    elements: [],
                    showHeader: true,
                });
            }
            this.newClassStrs.forEach((clazz) => {
                this.potentialValue = "";
                const node = this.prepRowElement(this.potentialValue);
                this.newNodes[clazz] = node;
                const newNode = Dom.node(node);
                this.setupNewNode(newNode, clazz);
                this.connect(newNode, Input.enter, () => this.onMouseOver(null, newNode));
                this.connect(newNode, Input.leave, () => this.onMouseOut(null, newNode));
                Dom.hide(node);
            });
        }
    }
    // Returns the string to use as the new class.
    protected defaultNewClassStr() {
        return this.newClassStrs[0];
    }
    // draw the right row as selected by cursor
    protected drawSelectedByCursor(jumpScroll = true) {
        this.drawSelected(jumpScroll);
    }
    // draw the right row as selected by arrow keys
    protected drawSelectedByKeys(jumpScroll = true) {
        this.drawSelected(jumpScroll, ["hovered", "focus-no-space-style"], true);
    }
    protected drawSelected(
        jumpScroll = true,
        classes: string[] = ["hovered"],
        selectedByKey = false,
    ) {
        if (selectedByKey) {
            this.hovered?.node.dispatchEvent(MOUSEOUT_EVENT);
        }
        this.unhover();
        // If the user hasn't selected anything with the keyboard yet, do nothing.
        if (this.selectedIdx == null) {
            return;
        }
        // how many items are visible? we don't just count elements because
        // the new node option may be there
        const cnt = this.visible.length;
        if (this.selectedIdx >= cnt) {
            this.selectedIdx = 0;
        } else if (this.selectedIdx < 0) {
            this.selectedIdx = cnt - 1;
        }
        if (this.selectedIdx < cnt && this.selectedIdx >= 0) {
            const e = this.visible[this.selectedIdx];
            Dom.addClass(e.node, classes);
            jumpScroll && dojo_window.scrollIntoView(e.node);
            if (selectedByKey) {
                // Manually triggers the mouseover event so that tooltips show from keyboard navigation
                e.node.dispatchEvent(MOUSEOVER_EVENT);
            }
            this.hovered = e;
        }
    }
    protected _filter(val: string): Promise<any> {
        this.buildVisible(val, this.customSearch, this.retainDefaultSearch);
        this.updateIndexes();
        if (this.visible.length === 0) {
            this.unhover();
            if (this.shouldShowNoResultsDiv()) {
                Dom.show(this.noResults);
            }
        } else {
            Dom.hide(this.noResults);
        }
        if (val.length > 0) {
            this.selectedIdx = 0;
        }
        this.drawSelectedByCursor();
        return Promise.resolve();
    }
    // get the list of elements for elem's class.
    protected forClass(className: string, create = false) {
        if (!Is.defined(this._elements[className]) && create) {
            this.addClass(className);
        }
        return this._elements[className];
    }
    protected forEachElement(f: (e: SelectElement<T>) => void) {
        if (this._elements) {
            Object.values(this._elements).forEach((clazz) => {
                clazz.elements.forEach(f);
            });
        }
    }
    // generic helper method for searching for an element and doing something if it is found or not,
    // with an optional create argument saying whether you should create it if not found
    protected searchElements(
        elems: T | T[],
        ifFound?: (clazz: SelectForClass<T>, idx: number) => void,
        ifNotFound?: (clazz: SelectForClass<T>, idx: number) => void,
        create = false,
    ) {
        Arr.wrap(elems).forEach((e) => {
            this.searchElement(e, ifFound, ifNotFound, create);
        });
    }
    private searchElement(
        elem: T,
        ifFound?: (clazz: SelectForClass<T>, idx: number) => void,
        ifNotFound?: (clazz: SelectForClass<T>, idx: number) => void,
        create = false,
    ) {
        ifFound = ifFound || function () {};
        ifNotFound = ifNotFound || function () {};

        const forClass = this.forClass(this.getClassName(elem), create);
        const comp = (val: T, e: SelectElement<T>) => this.comparator(val, e.element);
        const idx = forClass ? Arr.binarySearch(forClass.elements, elem, comp) : -1;
        if (idx >= 0) {
            ifFound(forClass, idx);
        } else {
            ifNotFound(forClass, -(idx + 1));
        }
    }
    protected _handleKey(k: number, evt: KeyboardEvent) {
        if (!evt) {
            return true;
        }
        // arrows up and down move the selector
        if (k === dojo_keys.UP_ARROW || k === dojo_keys.DOWN_ARROW) {
            // If the user hasn't hit a key yet, start right "above" the first item.
            if (this.selectedIdx == null) {
                this.selectedIdx = -1;
            }
            this.selectedIdx += k === dojo_keys.UP_ARROW ? -1 : 1;
            this.drawSelectedByKeys();
            return false;
        } else {
            return this.handleKey(k, evt);
        }
    }
    protected _onTextBoxBlur() {
        this.minimize();
        if (this._selected) {
            this.setValueOnBlur();
        }
        this.onTextBoxBlur();
    }
    protected onTextBoxFocus() {
        if (this.suppressTextBox) {
            Widget.blurAll();
            return;
        }
        if (!this.disabled) {
            this.popupPositioner && this.popupPositioner.show();
            if (this.selectedIdx == null) {
                this.selectedIdx = 0;
            }
            this.doKeySelect();
            this.onPopup();
        }
    }
    setTextBoxAriaLabel(ariaLabel: string) {
        this.tb.setTextBoxAriaLabel(ariaLabel);
    }
    setTextBoxLabelContent(labelContent: Dom.Content) {
        this.tb.setTextBoxLabelContent(labelContent);
    }
    setTextBoxLabelPosition(position: TextBox.LabelPosition) {
        this.tb.setTextBoxLabelPosition(position);
    }
    protected onClick() {
        this.focus();
    }
    protected onUpdate() {
        this._filter(this.tb.getValue());
    }
    protected setupNewNode(newNode: HTMLElement, clazz: string) {
        this.forClass(clazz, true); // Create new class if doesn't already exist
        let idx: number;
        let color: string = EverColor.EVERBLUE_40;
        if (
            (idx = Arr.first(this.newClassStrs, (newClazz) => newClazz === clazz)) >= 0
            && idx < this.newColors.length
        ) {
            color = this.newColors[idx];
        }
        if (this.colorItems) {
            Dom.style(newNode, "borderLeft", "8px solid " + color);
        }
        Dom.addClass(newNode, "new-row");
        this.connect(newNode, Input.tap, () => {
            this.addNew(clazz);
        });
    }
    protected prepRowElement(e: T | string): BaseSelect.Row {
        const everHash = !this.hasTextbox() ? everHashProp(this.shortDisplay(e)) : {};
        return {
            node: Dom.div(
                {
                    class: this.getRowElementCssClass(),
                    style: { position: "relative" },
                    ...everClassProp(EVERCLASS.BASE_SELECT.OPTION),
                    ...everHash,
                },
                Dom.span({ class: "label-node" }, this.shortDisplay(e)),
            ),
            onDestroy: [],
        };
    }
    private prepNewClassInlineRowElement(classDisplay: string) {
        return Dom.create("div", {
            class: this.getRowElementCssClass(),
            style: { position: "relative" },
            content: Dom.node(
                new LabeledIcon("plus-blue-20", {
                    label: `Create new ${classDisplay}`,
                }),
            ),
            ...everClassProp(EVERCLASS.BASE_SELECT.OPTION),
        });
    }
    protected getRowElementCssClass(): string {
        return clsx("table-row action description", {
            "dont-show-select-icon": this.dontShowSelectIcon,
        });
    }
    protected removeElement(e: T, idx: number, silent = false) {
        const byClass = this._elements[this.getClassName(e)];
        byClass.elements[idx].destroy();
        byClass.elements.splice(idx, 1);
        this._onToggleInner(e, false, silent);
    }
    protected selectInitial(silent = true): void {
        return;
    }
    // callback for when the popup (if there is one) is blurred
    protected setKeySelectToElement(elem: T) {
        this.visible.forEach((entry: NodeWithNullableElement<T>, idx: number) => {
            if (entry.element && entry.element.equals(elem)) {
                this.selectedIdx = idx;
            }
        });
        this.drawSelectedByCursor();
    }
    protected setRowLabelContent(labeledNode: HTMLElement, ...content: Dom.Content[]) {
        const labelNode = labeledNode.classList.contains("label-node")
            ? labeledNode
            : labeledNode.querySelector(".label-node");

        if (labelNode) {
            Dom.setContent(labelNode, content);
        }
    }
    protected shouldCreateNewNode() {
        return this.newOption;
    }
    protected shouldShowNoResultsDiv() {
        return true;
    }
    // there is no anti-selecting in this single select
    protected toggle(
        elems: T | T[],
        silent?: boolean,
        add?: boolean,
        readOnlyOverride?: boolean,
        alsoSetValue?: boolean,
    ) {
        this.searchElements(elems, (clazz, idx) => {
            // Use the real element rather than whatever's provided
            const elem = clazz.elements[idx];
            this.toggleInner(elem.row(), elem.element, silent, add, readOnlyOverride, alsoSetValue);
        });
    }
    protected toggleInner(
        node: Dom.Nodeable,
        elem: T,
        silent?: boolean,
        add?: boolean | MouseEvent,
        readOnlyOverride?: boolean,
        alsoSetValue?: boolean,
    ) {
        const readOnly = this.readOnly(elem);
        if (readOnly && !readOnlyOverride) {
            return;
        }
        const isSelected = Dom.hasClass(node, this.SELECTED_CLASS);
        // Is add a click event? If so, determine if we're adding the object.
        if (add !== !!add) {
            add = !isSelected || this.selectOnSame;
        }
        if (add) {
            if (isSelected) {
                if (this.selectOnSame && !silent) {
                    this._onSelect(elem, false, node);
                }
            } else {
                this._onToggleInner(elem, true, !!silent);
                if (!this.showDisabledReadOnly || this.showReadOnlySelected || !readOnly) {
                    this.setSelected(node, true);
                }
                if (!silent) {
                    this._onSelect(elem, false, node);
                }
            }
        } else if (!add && isSelected) {
            this._onToggleInner(elem, false, !!silent);
            this.setSelected(node, false);
            if (!silent) {
                this._onUnselect(elem, false, node);
            }
        }
    }
    setSelected(row: Dom.Nodeable, selected = true): void {
        const isSelected = Dom.hasClass(row, this.SELECTED_CLASS);
        if (isSelected === selected) {
            return;
        }
        Dom.toggleClass(row, this.SELECTED_CLASS, selected);
        if (this.dontShowSelectIcon) {
            return;
        }
        // Add or remove the check icon appropriately, but never for new nodes. It's a bit of a
        // hack, but it would be a significant refactor to extend 'row' here to keep a reference to
        // its check icon.
        if (!this.isNewNode(Dom.node(row))) {
            if (selected) {
                const icon = new Icon("check-blue-20");
                Dom.addClass(icon, "base-select__selected-check-icon");
                Dom.place(icon, row, "first");
            } else {
                const firstChild = Dom.node(row).children[0];
                if (firstChild.classList.contains("icon_check-blue-20")) {
                    Dom.destroy(firstChild);
                }
            }
        }
    }
    protected toggleUserHidden(elems: T | T[], hidden: boolean) {
        this.searchElements(elems, (clazz, idx) => {
            clazz.elements[idx].hidden = hidden;
        });
        this.onUpdate();
    }
    unhover() {
        if (this.hovered) {
            Dom.removeClass(this.hovered.node, ["hovered", "focus-no-space-style"]);
            this.hovered = null;
        }
    }

    // Update the internal text to highlight the match. To allow for HTML formatting, if the node is
    // not a string we just assume it matches but don't highlight anything. If we ever want to allow
    // for formatting in searchable selectors this behavior needs to be modified appropriately.
    protected highlightMatches(row: BaseSelect.Row, disps: Dom.Content[], searchVal: string) {
        const matchStyles = this.matchStyles();
        const rowLabelContent: HTMLElement[] = [];

        disps.forEach((disp: Dom.Content, index: number) => {
            const dispHighlighted = this.highlightDomContent(disp, index, searchVal);

            const dispDiv = Dom.div(
                { style: "display: inline-block;" + (matchStyles[index] || "") },
                dispHighlighted,
            );

            if (this.ellipsifyText) {
                Dom.style(dispDiv, "display", "block");
                Dom.addClass(dispDiv, this.ELLIPSIFY_CLASS);
            }

            rowLabelContent.push(dispDiv);
        });

        this.setRowLabelContent(row.node, rowLabelContent);
    }

    protected highlightMatchesWithCaptions(
        row: BaseSelect.Row,
        disps: Dom.Content[],
        caps: Dom.Content[],
        searchVal: string,
    ): void {
        const matchStyles = this.matchStyles();
        const matchCapStyles = this.matchCapStyles(caps);
        const rowLabelContent: HTMLElement[] = [];
        const dispDivs: HTMLElement[] = [];
        const dispCapsDivs: HTMLElement[] = [];

        disps.forEach((disp: Dom.Content, index: number) => {
            const dispHighlighted = this.highlightDomContent(disp, index, searchVal);
            const dispDiv = Dom.div(
                { style: "display: inline-block;" + (matchStyles[index] || "") },
                dispHighlighted,
            );
            if (this.ellipsifyText) {
                Dom.style(dispDiv, "display", "inline-block");
                Dom.addClass(dispDiv, this.ELLIPSIFY_CLASS);
            }
            dispDivs.push(dispDiv);
        });
        let rowLabelElement: HTMLElement;
        if (caps.length === 0) {
            rowLabelElement = Dom.div(Dom.span({ class: "label-node" }, dispDivs));
        } else {
            if (this.ellipsifyText) {
                rowLabelElement = this.ellipsifyCaptions(dispDivs, caps, searchVal, matchCapStyles);
            } else {
                caps.forEach((disp: Dom.Content, index: number) => {
                    const dispHighlightedCaps = this.highlightDomContent(disp, index, searchVal);
                    const dispCapDiv = Dom.div(
                        { style: "display: inline-block;" + (matchCapStyles[index] || "") },
                        dispHighlightedCaps,
                    );
                    Dom.addClass(dispCapDiv, "label-node-caption");
                    dispCapsDivs.push(dispCapDiv);
                });
                rowLabelElement = Dom.div(
                    Dom.div(Dom.span({ class: "label-node" }, dispDivs)),
                    Dom.div({ class: "label-node-caption" }, dispCapsDivs),
                );
            }
        }
        rowLabelContent.push(rowLabelElement);
        this.setRowLabelContent(row.node, rowLabelContent);
    }

    private ellipsifyCaptions(
        dispDivs: HTMLElement[],
        caps: Dom.Content[],
        searchVal: string,
        matchCapStyles: string[],
    ) {
        let dispCapsDivs: HTMLElement[] = [];
        caps.forEach((disp: Dom.Content, index: number) => {
            const dispHighlightedCaps = this.highlightDomContent(disp, index, searchVal);
            const capContent: HTMLElement[] = [];
            dispHighlightedCaps.forEach((capDisp) => {
                if (!(capDisp instanceof HTMLElement)) {
                    capContent.push(Dom.span({ style: matchCapStyles[index] || "" }, capDisp));
                } else {
                    capContent.push(capDisp);
                }
            });
            Dom.addClass(capContent, "label-node-caption");
            dispCapsDivs = dispCapsDivs.concat(capContent);
        });
        return Dom.div(
            Dom.div(Dom.span({ class: "label-node" }, dispDivs)),
            Dom.div({ class: "label-node-caption display-ellipses" }, dispCapsDivs),
        );
    }

    private highlightDomContent(disp: Dom.Content, index: number, searchVal: string) {
        const dispHighlighted: Dom.Content[] = [];
        if (searchVal && Is.string(disp)) {
            const idx = disp.toLowerCase().indexOf(searchVal);

            if (idx !== -1) {
                dispHighlighted.push(
                    disp.substring(0, idx),
                    Dom.span({ class: "highlighted" }, disp.substring(idx, idx + searchVal.length)),
                    disp.substring(idx + searchVal.length),
                );
            } else {
                dispHighlighted.push(disp);
            }
        } else {
            dispHighlighted.push(disp);
        }
        return dispHighlighted;
    }

    protected matchStyles(): string[] {
        return [""];
    }

    protected matchCapStyles(caps: Dom.Content[]): string[] {
        return [""];
    }

    /**
     * Set the indexes for each visible element (for updating highlights).
     */
    protected updateIndexes() {
        this.visible.forEach((entry: NodeWithNullableElement<T>, idx: number) => {
            Dom.setAttr(entry.node, "index", "" + idx);
        });
    }
    protected _updateNewNode(val: string, clazz: string, newIdx: number) {
        const newNode = Dom.node(this.newNodes[clazz]);
        if (val.trim().length > 0 && this.canAdd(val, clazz)) {
            this.potentialValue = val;
            // update the new node
            this.setRowLabelContent(newNode, Dom.span({ class: "highlighted" }, val));
            Dom.show(newNode);
            if (
                this.newOption
                && this.headers
                && this._elements[clazz].header
                && this._elements[clazz].showHeader
            ) {
                Dom.show(this._elements[clazz].header);
            }
            this.visible.splice(newIdx, 0, { node: newNode });
            this.selectedIdx = 0;
            this.drawSelectedByCursor();
            return true;
        } else {
            Dom.hide(newNode);
            return false;
        }
    }
    // Permanently hide a specific header. Useful, for example, if you want to have a section of
    // one or more entries at the top of the list without a header.
    hideHeader(clazz: string) {
        const selectForClass = this._elements[clazz];
        if (selectForClass && selectForClass.header) {
            selectForClass.showHeader = false;
            Dom.hide(selectForClass.header);
        }
    }
    isInside(node: HTMLElement) {
        return this.node.contains(node) || this.menu.contains(node);
    }

    /**
     * This removes a fixed number of characters from the beginning of every selection element,
     * starting instead with a left ellipsis.  The number of characters is determined from
     * the element with the most characters, and subtracting the parameter truncateOnLeftWidth.
     * This is intended to use when all selection elements start with the same string, like
     * with autocomplete.
     */
    protected computeLeftTruncateOffset(elems: T[][]) {}

    private createNoResultsRow(): void {
        this.noResults = Dom.create("div", {
            content: "No results.",
            class: "hidden table-row no-results",
            style: { backgroundColor: "white" },
        });
        this.positionNoResultsRow();
    }

    protected positionNoResultsRow(): void {
        Dom.place(this.noResults, this.menu, "last");
    }

    setNoResultsContent(content: Dom.Content) {
        Dom.setContent(this.noResults, content);
    }

    setNoResults(noResults: HTMLElement) {
        this.noResults = noResults;
        this.positionNoResultsRow();
    }
}

module BaseSelect {
    export interface Filtering<S, T extends Base.Object> {
        // When true, we allow a new item to be created that differs from an existing one in case only.
        caseSensitive: boolean;
        // If true, will deselect all elements when the text box is cleared.
        clearOnEmptyText: boolean;
        focusOnTap: boolean;
        // An array of new classes that can be added
        newClassStrs: string[];
        // An array of color hex strings that correspond to newClassStrs
        newColors: string[];
        // can you create new objects from this selector?
        newOption: boolean;
        placeholder: string | null;
        tabIndex?: number;
        // The textbox for filtering this select.
        tb: TextBox;
        // params to pass to textbox creation
        textBoxParams: TextBox.Params | null;
        textBoxWidth: string;
        addNew(): void;
        // can you add a new element with the given name of the given class?
        canAdd(name: string, clazz: string): boolean;
        clear(): void;
        // when you hit enter, you should toggle the current element (or add a new one if you are
        // on the new row)
        enterKey(): void;
        // should this existing element be considered the same as a new element with this name?
        equalsNew(existing: T, name: string): boolean;
        // display only elements that contain `val` as a substring
        filter(val: string): void;
        getTextValue(): string;
        // allow the user to handle special keys if they want.
        handleKey(k: number, evt: KeyboardEvent): boolean;
        onTextBoxBlur(): void;
        setDisabled(disabled: boolean): void;
        setTextboxWidth(width: string): void;
        setValue(val: S | string, silent?: boolean): void;
        updateNewNode(val: string, clazz: string, newIdx: number): void;
    }

    export interface Row {
        node: HTMLElement;
        onDestroy: Util.Destroyable[];
    }

    export interface Params<S, T extends Base.Object> extends UI.WidgetWithTextBoxParams {
        // elements should be a list of lists, each sublist being all the elements of a single class
        // you can also pass in a single list of all one class.
        // NOTE: This is optional, in Autocomplete cases getCompletions is used instead.
        elements?: T[] | T[][];

        // Limit number elements rendered at once. This is used for performance when there are
        // large numbers of elements. No limit is applied if this is omitted or has falsy value.
        maxRenderedElements?: number;

        // can you add a new element with this name?
        canAdd?: (name: string) => boolean;
        // When true, we allow a new item to be created that differs from an existing one in case only.
        caseSensitive?: boolean;
        // you can leave this null, in which case classes will be ordered as they appear
        // this should be an array of EBO classes e.g. ESI.Code OR an array of class names.
        classOrder?: ({ prototype: { className: string } } | string)[];
        // If true, class will maintain the given ordering, even when the top search result is not
        // in the first class. When set to false, classOrder only sets the initial ordering.
        classOrderOnSearch?: boolean;
        clearMark?: boolean;
        clearOnEmptyText?: boolean;
        // When set, adds a left border (which indicates the element's color) to element's display.
        //  - 'element' (the default setting) first tries to get the color from the element before
        //    falling back to the system default color (everblue)
        //  - 'default' uses the default everblue automatically
        //  - any other string is treated as a CSS color
        //  - explicitly set to null if you don't want coloring on elements
        colorItems?: "element" | "default" | string | ((e: T) => string);
        comparator?: (x: T, y: T) => number;
        // create your new element with the given name.
        // should call callback with the EBO as the sole argument once it is completed
        createNew?: (name: string, clazz: string, success: (obj: T) => void) => void;
        // A custom search function.
        customSearch?: (display: any, search: string) => boolean;
        updateCaptionOnSearch?: (display: any, search: string) => string;
        handleCaptions?: boolean;
        // objOrName is a string iff a new object is added. Otherwise, it is the
        // object that was selected.
        display?: (objOrName: T | string) => string;
        // When set, ellipsifies the long text that do not fit within the width of the popup.
        // This only makes sense to use with mirrorTooltips? to view the full text on hover.
        ellipsifyText?: boolean;
        // should this existing element be considered the same as a new element with this name?
        equalsNew?: (existing: T, name: string) => boolean;
        focusOnTap?: boolean;
        // Default returns a list with one display value. Override to include multiple values.
        getDisplayValues?: (e: T, me: BaseSelect<S, T>) => Dom.Content[];
        // Default returns the display values. Override to decide what to filter on when typing into the
        // text box. Note that this should return an array of strings, but is typed as Dom.Content[]
        // so that it can default to getDisplayValues()
        getFilterValues?: (e: T, me: BaseSelect<S, T>) => Dom.Content[];
        getCaptionValues?: (e: T, val: string) => Dom.Content[];
        // Returns the label(s) of a given user to display as a tooltip.
        getRowBodyTooltips?: (e: T, me: BaseSelect<S, T>) => Dom.Content;
        getHeader?: (elem: T) => string;
        // allow the user to handle special keys if they want.
        handleKey?: (k: number, evt: KeyboardEvent) => void;
        // show headers?
        headers?: boolean;
        // A function that returns the icon class to display for the provided element.
        // This does NOT override the readOnly icon.
        icon?: (elem: T) => string | null;
        // Config the icon displayed for the provided element.
        iconConfig?: (elem: T, icon: Icon) => void;
        iconTooltip?: (elem: T) => Dom.Content;
        // Whether to float the icon all the way right.
        iconFloatRight?: () => boolean;
        // element(s) silently selected during construction of this widget, ignores readOnly status
        initialSelected?: T | T[];
        // matchStyles styles each of the elements returned by getDisplayValues
        matchStyles?: () => string[];
        // matchCapStyles styles each of the caption elements returned by getCaptionValues
        matchCapStyles?: (caps: string[]) => string[];
        // Menu popout width should match the textbox. True by default.
        matchWidth?: boolean;
        // Max height of the menu (must specify px, %, etc.)
        menuMaxHeight?: string;
        // If `popup` is not false, this sets the popup to minimize on any document body tap
        // (as opposed to just when the toggler is tapped).
        minimizePopupOnAll?: boolean;
        // Whether elements that are truncated should have a mirror tooltip.
        mirrorTooltips?: boolean;
        // Position of the mirror tooltip.
        // See description of `dijit/Tooltip.defaultPosition` for details on position parameter
        mirrorTooltipPosition?: string[];
        // A map from className -> displayName to make user-friendly headers
        nameMap?: { [className: string]: string };
        // This parameter is kinda a hack to resolve a Safari/IE focus bug. It's not clear exactly why,
        // but without this (e.g. set it to false for all Selects), pressing "Enter" to move from the
        // Select widget to the next widget in the "Predicted" search term doesn't work. The same bug
        // also affects several other search terms: Uploaded, Produced, Assigned. What seems to happen
        // is that when you press "Enter," focus moves from the current (Select) widget to the next
        // widget, but then immediately moves back to the original Select widget due to this.tb.select().
        // (This is probably due to event handlers happening in different orders in different browsers.)
        // This solution is just to not call this.tb.select(), since we don't need it in this case (since
        // we're moving focus to another element anyway).
        neverSelectAfter?: boolean;
        // What class and color should new objects have? If `newClass` and `newColor` are arrays, they
        // should be the same length, with equal indices corresponding.
        newClass?: { prototype: { className: string } } | { prototype: { className: string } }[];
        // Classes that should include the "+ Create new <className>" row.
        newClassesInline?: NewClassesInline<S, T>;
        newColor?: string | string[];
        newOption?: boolean;
        onBlur?: () => void;
        onChange?: (
            elem: T | string,
            wasAdded: boolean,
            allSelected: T | { [className: string]: T[] },
        ) => void;
        onFilter?: (val: string) => void;
        afterFilter?: () => void;
        onFocus?: () => void;
        // callbacks for when the user mouses off or over a row
        onMouseOut?: (elem: T, rowNode: HTMLElement) => void;
        onMouseOver?: (elem: T, rowNode: HTMLElement) => void;
        onPopup?: () => void;
        // elem: the element that was just selected (which may be already selected if this.selectOnSame)
        // isNew: is the element newly added? (only defined if this is a comboBox)
        // selector: this
        onSelect?: (
            elem: T,
            isNew?: boolean,
            selector?: BaseSelect<S, T>,
            selectedNode?: Dom.Nodeable,
        ) => void;
        onEnterKey?: (elem?: T, dontSelect?: boolean) => void;
        onTextBoxBlur?: () => void;
        onUnselect?: (elem: T, unselectedNode?: Dom.Nodeable) => void;
        placeholder?: string;
        // If classNames that are not in nameMap should be pluralized.
        pluralize?: boolean;
        // If pluralize is true, this string will be used in place of className + "s". Useful if
        // className + "s" is not the correct plural for className (e.g. "Category" => "Categories")
        pluralOverride?: string;
        // should this be a popup? can be false or "after" or "before"
        // after means the popup is after (below) the toggler, and vice versa for before
        popup?: boolean | string;
        // Additional css class to add to the popup
        popupClass?: string;
        // Should the popup always be in the direction specified in "popup"?
        forceDirection?: boolean;
        // Returns the content to display for a given element or string. The content should contain an
        // element with the class "label-node" that will contain the label of the element to display.
        prepRowElement?: (e: T | string) => Row;
        // Can the object's selected status not be changed? Return null if not readOnly; else return a
        // string explaining why readOnly
        readOnly?: (elem: T) => string | null;
        // If false or undefined, customSearch will be the only search function used. If true, both
        // customSearch and the existing search functionality will be used.
        retainDefaultSearch?: boolean;
        // This style is applied to the overall selector body including textbox and menu
        selectorStyle?: Dom.StyleProps;
        // This style class is applied to the overall selector body including textbox and menu.
        selectorClass?: string;
        shouldCreateNewNode?: () => boolean;
        // Should show the row of "No results." if no match for entered text
        shouldShowNoResultsDiv?: () => boolean;
        // If true, the currently-selected elements will be displayed at the top of their class
        // in the selector.
        showSelectedElementsFirst?: boolean;
        // Custom method to determine which elements should be considered "selected" for the above,
        // if one does not wish to use the actual selected status of an element
        isSelectedForSorting?: (elem: T) => boolean;
        // This style is applied to the menu, not the selector textbox.
        style?: Dom.StyleProps;
        tabIndex?: number;
        textBoxParams?: TextBox.Params;
        textBoxWidth?: string;
        // If `popup` is not false, user can optionally specify the toggler node.
        toggler?: Dom.Nodeable;
        // If the user has already created the select div, pass it here to avoid creation.
        selectDiv?: HTMLElement;
        // The text to display in the toggler
        togglerText?: string;
        // If `popup` is not false, the zIndex of the list of the popup.
        zIndex?: string;
        manualFilter?: boolean;
        truncateOnLeftWidth?: number;
        // use dojo_touch.press rather than dojox_gesture_tap for the click action on individual elements.
        // Seems to work well on some selects and terribly on others.
        usePress?: boolean;
        // hide [newly added] suffix on newly created elements
        hideNewSuffix?: boolean;
        // Whether to show the readOnlyIconClass icon for a read only object.
        showReadOnlyIcon?: boolean;
        // The icon shown for a read only object if showReadOnlyIcon is true. Default is 'lock'.
        readOnlyIconClass?: string;
        // Show read-only elements as grayed-out elements with tooltips as returned by 'readOnly'.
        showDisabledReadOnly?: boolean;
        // If read-only elements should be selectable when showDisabledReadOnly is true.
        showReadOnlySelected?: boolean;
        // Apply a css class to a given row of the selector.
        getClassForRow?: (e: Base.Object) => string;
        clickableIcon?: boolean;
        onMinimize?: (me: BaseSelect<S, T>) => void;
        // This refers to the toggler being focusable.
        makeFocusable?: boolean;
        focusStyling?: string | string[];
        // This refers to the contained elements being focusable.
        makeElementsFocusable?: boolean;
        elementFocusStyling?: string | string[];
        textBoxContainer?: HTMLElement;
        // For subclasses that don't want the check icon used to represent selected items.
        dontShowSelectIcon?: boolean;
    }

    /**
     * Defines sections that should include the "+ Create new <className>" row. This is a map from
     * classname to: {
     *   createNew: A function to be called when the selector is pressed. This should do any work
     *     necessary to add the newly created object to the selector (if required), such as adding it
     *     directly to the selector argument or a Base Store.
     *   color: Optional color to style the row.
     * }
     */
    export type NewClassesInline<S, T extends Base.Object> = {
        [className: string]: {
            createNew: (selector?: BaseSelect<S, T>) => void;
            color?: string;
            classDisplay?: string;
        };
    };
}

export = BaseSelect;
